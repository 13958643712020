import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function Transportation({ transportationRef }) {
  const [options, setOptions] = useState([true, false, false]);
  const [selected, setSelected] = useState("1");

  const onToggle = (num) => {
    let newOptions = options.map((el, index) =>
      index !== num ? (el = false) : (el = true)
    );
    setOptions(newOptions);
  };

  console.log(selected);
  console.log(options);

  return (
    <div className="transportation-section-wrapper" ref={transportationRef}>
      <h1>About Bansko</h1>

      <div className="mobile-wrapper">
        <div className="option">Getting to bansko</div>

        <div className="transport-wrapper">
          <div className="transport-heading">By plane</div>
          <div className="text border">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By car</div>
          <div className="text border">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By train and bus</div>
          <div className="text">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>

          <div className="map">Map</div>
        </div>

        <div className="option">Getting around town</div>

        <div className="transport-wrapper">
          <div className="transport-heading">By plane</div>
          <div className="text border">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By car</div>
          <div className="text border">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>
        </div>

        <div className="transport-wrapper">
          <div className="transport-heading">By train and bus</div>
          <div className="text">
            You can fly into Munich (MUC) or Innsbruck Airport (INN). Bus
            transfer (not included in the package) will be available from both
            airports. As soon as we know how many people will be arriving via
            MUC or INN , we will work out what is the best option and let you
            know as soon as possible. MUC: approx. 240 km, expect 4.5h bus ride
            INN: approx. 110 km, expect 2h bus ride To arrive on time for the
            opening ceremony, we recommend arriving at Munich Airport no later
            than 12:00 local time!
          </div>

          <div className="map">Map</div>
        </div>
      </div>

      <div className="options-wrapper">
        <div
          className={`destination first ${selected === "1" ? "selected" : ""}`}
          onClick={() => setSelected("1")}
        >
          Getting to bansko
        </div>
        <div
          className={`destination ${selected === "2" ? "selected" : ""}`}
          onClick={() => setSelected("2")}
        >
          About the city
        </div>
        <div
          className={`destination last ${selected === "3" ? "selected" : ""}`}
          onClick={() => setSelected("3")}
        >
          Getting around town
        </div>
      </div>

      <div className="main-content">
        {selected === "1" && (
          <>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23781.250489447895!2d23.469405711368758!3d41.835698320419596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14abaeb946493c25%3A0xd6b0eaa049fe85c7!2zMjc3MCDQkdCw0L3RgdC60L4!5e0!3m2!1sbg!2sbg!4v1672745726643!5m2!1sbg!2sbg"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="info-wrapper">
              <div className={`option-wrapper ${options[0] ? "selected" : ""}`}>
                <h2>By plane</h2>
                {options[0] ? (
                  <p>
                    You can fly into Munich (MUC) or Innsbruck Airport (INN).
                    Bus transfer (not included in the package) will be available
                    from both airports. As soon as we know how many people will
                    be arriving via MUC or INN , we will work out what is the
                    best option and let you know as soon as possible. MUC:
                    approx. 240 km, expect 4.5h bus ride INN: approx. 110 km,
                    expect 2h bus ride To arrive on time for the opening
                    ceremony, we recommend arriving at Munich Airport no later
                    than 12:00 local time!
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(0)}
                  />
                )}
              </div>

              <div className={`option-wrapper ${options[1] ? "selected" : ""}`}>
                <h2>By car</h2>
                {options[1] ? (
                  <p>
                    110 km, expect 2h bus ride To arrive on time for the opening
                    ceremony, we recommend arriving at Munich Airport no later
                    than 12:00 local time!
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(1)}
                  />
                )}
              </div>

              <div
                className={`option-wrapper last ${
                  options[2] ? "selected" : ""
                }`}
              >
                <h2>By train and bus</h2>
                {options[2] ? (
                  <p>
                    You can fly into Munich (MUC) or Innsbruck Airport (INN).
                    Bus transfer (not included in the package) will be available
                    from both airports.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(2)}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {selected === "2" && (
          <>
            <div className="map">
              <img src={require("../../assets/images/about-the-city.png")} />
            </div>

            <div className="info-wrapper">
              <div className={`option-wrapper ${options[0] ? "selected" : ""}`}>
                <h2>General Information</h2>
                {options[0] ? (
                  <p>
                    You can fly into Munich (MUC) or Innsbruck Airport (INN).
                    Bus transfer (not included in the package) will be available
                    from both airports.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(0)}
                  />
                )}
              </div>

              <div
                className={`option-wrapper last ${
                  options[1] ? "selected" : ""
                }`}
              >
                <h2>Bulgarian Cuisine</h2>
                {options[1] ? (
                  <p>
                    The cuisine of Bansko is unique for the borders of the town.
                    The answer to that is in the development of Bansko as a town
                    of merchants in the 18th century. While traveling to
                    Austro-Hungarian Empire, Italy and Germany with their
                    caravan, the tradesman of Bansko were bringing back
                    influences and inspiration.
                  </p>
                ) : (
                  <img
                    src={require("../../assets/images/plus-icon.svg").default}
                    onClick={() => onToggle(1)}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {selected === "3" && 
                  <>
                  <div className="map pointer">
                    <img src={require("../../assets/images/map-bansko.png")} onClick={() => window.open('https://www.banskoski.com/bg/map')} />
                  </div>
      
                  <div className="info-wrapper">
                    <div className={`option-wrapper ${options[0] ? "selected" : ""}`}>
                      <h2>Information</h2>
                      {options[0] ? (
                        <p>
                          You can fly into Munich (MUC) or Innsbruck Airport (INN).
                          Bus transfer (not included in the package) will be available
                          from both airports.
                        </p>
                      ) : (
                        <img
                          src={require("../../assets/images/plus-icon.svg").default}
                          onClick={() => onToggle(0)}
                        />
                      )}
                    </div>
      
                    <div
                      className={`option-wrapper last ${
                        options[1] ? "selected" : ""
                      }`}
                    >
                      <h2>Ski map</h2>
                      {options[1] ? (
                        <p>
                          Short description about the map
                        </p>
                      ) : (
                        <img
                          src={require("../../assets/images/plus-icon.svg").default}
                          onClick={() => onToggle(1)}
                        />
                      )}
                    </div>
                  </div>
                </>
        }
      </div>
    </div>
  );
}
