import React from "react";
import "./styles.scss";
import { HeaderRegistration } from "../Header";
import { FooterRegistration } from "../Footer";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import { useState, useEffect } from "react";
import moment from "moment";
import { AddTeamMember, DeleteTeamMember } from "../Modals";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Sheet from 'react-modal-sheet';
import { isInputValid } from "../../config/utilities";
import { addTeamMemberAsync, editTeamMemberAsync, deleteTeamMemberAsync, changeTab, setMemberId, resetState } from "../../reducers/registration";

export default function TeamMember() {
  const [ focusedElement, setFocusedElement ] = useState(false);
  const [ secondFocusedElement, setSecondFocusedElement ] = useState(false);
  const [ thirdFocusedElement, setThirdFocusedElement ] = useState(false);
  const [ isTeamLeaderExistent, setIsTeamLeaderExistent ] = useState(false);
  const [ isCurrentTeamLeader, setIsCurrentTeamLeader ] = useState(false);
  const [ isClicked, setIsClicked ] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [ open, setOpen ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);

  const id = useSelector(state => state.registration.id);
  const registrationState = useSelector(state => state.registration);
  const { memberId } = useParams()
  const members = useSelector(state => state.registration.members);
  const registrationInfo = members.find(member => member._id === memberId)
  const dispatch = useDispatch();

  const [payload, setPayload] = useState(
  {
    registrationId: "",
    isTeamLeader: false,
    fullName: "",
    birthdate: "",
    // birthdate: moment().subtract(18, 'years').startOf('year').toDate(),
    address: "",
    email: "",
    nationality: "",
    height: 0,
    shoeSize: 0,
    shirtSize: '',
    competitions: [],
    equipment: []
  }
  );

  const nationalities = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Armenian",
    "Austrian",
    "Azerbaijani",
    "Argentinian",
    "Australian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Belarusian",
    "Belizean",
    "Beninese",
    "Bermudian",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Botswanan",
    "British",
    "Bulgarian",
    "Burkinese",
    "Burundian",
    "Canadian",
    "Chinese",
    "Colombian",
    "Cuban",
    "Cambodian",
    "Cameroonian",
    "Cape Verdean",
    "Chadian",
    "Chilean",
    "Congolese",
    "Costa Rican",
    "Croat",
    "Cypriot",
    "Czech",
    "Danish",
    "Dominican",
    "Djiboutian",
    "Dominican",
    "Dutch",
    "Ecuadorean",
    "Egyptian",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Finnish",
    "French Polynesian",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Guatemalan",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Honduran",
    "Hungarian",
    "Indian",
    "Ireland",
    "Israeli",
    "Italian",
    "Icelandic",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Japanese",
    "Jamaican",
    "Jordanian",
    "Kazakh",
    "Kenyan",
    "North Korean",
    "Kuwaiti",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Lithuanian",
    "LUXEMBOURG",
    "Madagascan",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Mauritanian",
    "Mauritian",
    "Monacan",
    "Mongolian",
    "Montenegrin",
    "Moroccan",
    "Mozambican",
    "Mexican",
    "Namibian",
    "Nepalese",
    "New Zealand",
    "Nicaraguan",
    "Nigerien",
    "Nigerian",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Panamanian",
    "Guinean",
    "Paraguayan",
    "Peruvian",
    "Philippine",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Salvadorean",
    "Samoan",
    "Saudi Arabian",
    "Senegalese",
    "Serbian",
    "Sierra Leonian",
    "Singaporean",
    "Slovak",
    "Slovenian",
    "Slomoni",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Swedish",
    "Swiss",
    "Sri Lankan",
    "Sudanese",
    "Surinamese",
    "Swazi",
    "Taiwanese",
    "Tajik",
    "Thai",
    "Togolese",
    "Trinidadian",
    "Tunisian",
    "Turkish",
    "Turkoman",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Emirati",
    "Venezuelan",
    "Vietnamese",
    "Uruguayan",
    "Uzbek",
    "Vanuatuan",
    "Yemeni",
    "Zambian",
  ];
  //   const shoeSizes = Array(15).fill(35).map((size, index) => size = index + 1);
  const shoeSizes = [
    "35 EU",
    "36 EU",
    "37 EU",
    "38 EU",
    "39 EU",
    "40 EU",
    "41 EU",
    "42 EU",
    "43 EU",
    "44 EU",
    "45 EU",
    "46 EU",
    "47 EU",
    "48 EU",
    "49 EU",
    "50 EU",
    "51 EU",
    "52 EU",
  ];
  const shirtSizes = ["XS", "S", "M", "L", "XL"];
  const competitions = ["Ski", "Snowboard", "Ski-running"];
  const equipment = [
    "Skis",
    "Snowboard",
    "Ski boots",
    "Helmet",
    "Goggles",
    "Ski poles",
    "Sunglasses",
  ];

  const navigate = useNavigate();
  const { state } = useLocation();

  let isDisabled = payload.fullName === ""|| payload.fullName.length < 2 || payload.birthdate === "" || payload.address === "" || payload.address.length < 3 || payload.email === "" || !(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(payload.email) || payload.nationality === "" || payload.height === 0 || payload.shoeSize === 0 || payload.shirtSize === '' || payload.competitions.length === 0;
  let isEdit = state === 'edit' ? true : false;
  let nationalityQuery = '';
  let shoeSizeQuery = '';
  let shirtSizeQuery = '';

  useEffect(() => {
    setPayload({...payload, registrationId: id});

    let isNewTeamLeader = members.length > 0 && (members.filter(member => member.isTeamLeader === true).length > 0 ? true : false);
    setIsTeamLeaderExistent(isNewTeamLeader);

    // if (!isEdit) {
    //   let isNewTeamLeader = members.length > 0 && (members.filter(member => member.isTeamLeader === true).length > 0 ? true : false);
    //   setIsTeamLeaderExistent(isNewTeamLeader);
    // }
    if (isEdit) {
      let newPayload = JSON.parse(JSON.stringify(registrationInfo))
      delete newPayload._id
      delete newPayload.updatedAt
      delete newPayload.createdAt
      setPayload({...newPayload, birthdate: moment(registrationInfo.birthdate).toDate()})
      setIsCurrentTeamLeader(newPayload.isTeamLeader)
      dispatch(setMemberId(memberId))
    }

    if (registrationState?.isCompleted) {
      dispatch(resetState());
      navigate('/registration-info')
    }
  }, [])

  console.log('payload', payload)

  return (
    <div className="registration-initial-page-wrapper start">
      <HeaderRegistration />

      <div className="team-member">
        <div className="team-member-wrapper top">
          <div className="left-side">
            <img src={require("../../assets/images/x-icon.svg").default}
                 onClick={() => {
                  dispatch(changeTab('team members'))
                  navigate('/registration')
                 }} />
            {isEdit ? <h2>EDIT TEAM MEMBER</h2> : <h2>ADDING A TEAM MEMBER</h2>}
          </div>

         {isEdit ?
          <div className="bttn-wrapper desktop">
            <button
            className="bttn secondary"
            onClick={() => {
              setIsClicked(true)
              // navigate('/registration')
            }}
            >DELETE</button>
            <button
            disabled={isDisabled}
            className="bttn primary" 
            onClick={() =>{
              //  setIsClicked(true)
               dispatch(changeTab('team members'))
               dispatch(editTeamMemberAsync({ payload }))
               navigate('/registration')
              }}
            >SAVE</button>
          </div> 
         : <button
          disabled={isDisabled} 
          className={`add-member ${isDisabled ? 'disabled' : ''}`}
          onClick={() => {
          dispatch(addTeamMemberAsync({ payload }))
          setIsClicked(true)
          }}>ADD MEMBER</button>}
        </div>

        <div className="team-member-wrapper middle">
          <h3>GENERAL INFORMATION</h3>

          <div className="row">
            <div className="input-wrapper large">
              <p>
                First and last name <span className="red">*</span>
              </p>
              <input
              value={payload.fullName} 
              onChange={(e) => setPayload({...payload, fullName: e.target.value})}></input>
            </div>

            <div className="input-wrapper small">
              <p>
                Date of birth <span className="red">*</span>
              </p>

              <div className="calendar-wrapper">
                <DatePicker 
                showYearDropdown
                yearDropdownItemNumber={100}
                dateFormat="dd.MM.yyyy"
                maxDate={moment().subtract(12, 'years').startOf('year').toDate()}
                selected={payload.birthdate === '' ? '' : new Date(payload.birthdate)}
                onChange={(e) => setPayload({...payload, birthdate: moment(e).toDate()})}
                onKeyDown={(e) => e.preventDefault()} />
                <img
                  className="calendar-icon"
                  src={require("../../assets/images/calendar.svg").default}
                />
              </div>
            </div>

            <div className="input-wrapper large">
              <div className="label-wrapper">
                <p>
                  Address <span className="red">*</span>
                </p>
                {/* <img
                  src={require("../../assets/images/question-mark.svg").default}
                /> */}
              </div>
              <input 
              value={payload.address} 
              onChange={(e) => setPayload({...payload, address: e.target.value})}></input>
            </div>

            <div className="input-wrapper large">
              <p>
                Email address <span className="red">*</span>
              </p>
              <input 
              value={payload.email} 
              onChange={(e) => setPayload({...payload, email: e.target.value})}></input>
            </div>
          </div>

          <div className="row">
            <div className="input-wrapper large">
              <p>
                Nationality <span className="red">*</span>
              </p>

              <div className="input-arrow-wrapper">
                <input
                 type="text"
                 value={payload.nationality}
                 onClick={() => setFocusedElement(!focusedElement)}
                 onChange={(e) => setPayload({...payload, nationality: e.target.value})}
                 onBlur={() => {
                  setTimeout(() => {
                    if (!nationalities.includes(nationalityQuery)) {
                      nationalityQuery = ''
                      setPayload({...payload, nationality: ''})
                    }
                    setFocusedElement(false);
                  }, 150);
                }}>
                </input>
                <img
                  className={`arrow ${focusedElement ? 'selected' : ''}`}
                  src={require("../../assets/images/blue-arrow.svg").default}
                  onClick={() => setFocusedElement(!focusedElement)} />

                {focusedElement && (
                  <div className="dropdown-wrapper small">
                    {nationalities
                    .filter(el => el.toLowerCase().includes(payload.nationality.toLowerCase()))
                    .map((el, i) => {
                      return (
                        <div
                          key={i + 1031}
                          className="dropdown-element"
                          onClick={() => {
                            setPayload({ ...payload, nationality: el });
                            nationalityQuery = el;
                            setFocusedElement(false);
                          }}
                        >
                          {el}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="input-wrapper medium">
              <p>
                Height <span className="red">*</span>
              </p>

              <div className="text-input-wrapper">
                <input 
                value={payload.height === 0 ? '' : payload.height} 
                type="number"
                onChange={(e) => {
                  setPayload({...payload, height: Number(e.target.value)})
                }}></input>
                <div className="input-text">cm</div>
              </div>
            </div>

            <div className="input-wrapper medium">
              <p>
                Shoe size <span className="red">*</span>
              </p>

              <div className="input-arrow-wrapper">
                <input 
                 value={payload.shoeSize === 0 ? '' : `${payload.shoeSize} EU`}
                 onClick={() => setSecondFocusedElement(!secondFocusedElement)}
                //  onChange={(e) => setPayload({...payload, shoeSize: Number(e.target.value.slice(0, 2))})}
                 onBlur={() => {
                  setTimeout(() => {
                    if (!shoeSizes.includes(shoeSizeQuery)) {
                      shoeSizeQuery = ''
                      setPayload({...payload, shoeSize: 0})
                    }
                    setSecondFocusedElement(false);
                  }, 150);
                }}></input>
                <img
                  className={`arrow ${secondFocusedElement ? 'selected' : ''}`}
                  src={require("../../assets/images/blue-arrow.svg").default}
                  onClick={() => setSecondFocusedElement(!secondFocusedElement)} />

                {secondFocusedElement && (
                  <div className="dropdown-wrapper small">
                    {shoeSizes.map((el, i) => {
                      return (
                        <div
                          key={i + 1032}
                          className="dropdown-element"
                          onClick={() => {
                            setPayload({ ...payload, shoeSize: Number(el.slice(0, 2)) });
                            shoeSizeQuery = el;
                            setSecondFocusedElement(false);
                          }}
                        >
                          {el}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="input-wrapper medium">
              <p>
                T-shirt size <span className="red">*</span>
              </p>

              <div className="input-arrow-wrapper">
                <input
                 value={payload.shirtSize}
                 onClick={() => setThirdFocusedElement(!thirdFocusedElement)}
                //  onChange={(e) => setPayload({...payload, shirtSize: Number(e.target.value.slice(0, 2))})}
                 onBlur={() => {
                  setTimeout(() => {
                    if (!shirtSizes.includes(shirtSizeQuery)) {
                      shirtSizeQuery = ''
                      setPayload({...payload, shirtSize: ''})
                    }
                    setThirdFocusedElement(false);
                  }, 150);
                }}></input>
                <img
                  className={`arrow ${thirdFocusedElement ? 'selected' : ''}`}
                  src={require("../../assets/images/blue-arrow.svg").default}
                  onClick={() => setThirdFocusedElement(!thirdFocusedElement)}
                />

                {thirdFocusedElement && (
                  <div className="dropdown-wrapper small">
                    {shirtSizes.map((el, i) => {
                      return (
                        <div
                          key={i + 1030}
                          className="dropdown-element"
                          onClick={() => {
                            setPayload({ ...payload, shirtSize: el });
                            shirtSizeQuery = el;
                            setThirdFocusedElement(false);
                          }}
                        >
                          {el}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {(!isTeamLeaderExistent || (isEdit && isCurrentTeamLeader)) &&
            <div className="team-leader-wrapper">
            <div className="top">
              <h3>THIS USER IS THE TEAM LEADER</h3>

              <Switch
              checkedIcon={<img src={require('../../assets/images/Yes.svg').default} />}
              uncheckedIcon={<img src={require('../../assets/images/No.svg').default} />}
              onColor='#0083e5'
              offColor='#043C5E'
              borderRadius={18}
              checked={payload.isTeamLeader}
              onChange={(checked) => {
                // setIsTeamLeader(!isTeamLeader)
                // setIsTeamLeaderExistent(checked)
                setPayload({ ...payload, isTeamLeader: checked })
            }} 
              />
            </div>

            <p>
              The team leader will be contacted regarding organisational topics.
              Also he will be the one, responsible for the payments.{" "}
            </p>
          </div>}
        </div>

        <div className="team-member-wrapper bottom">
          <h3>EVENT INFORMATION</h3>

          <div className="events-wrapper">
            <div className="side left">
              <p>Competing in <span className="red">*</span></p>

              <div className="options">
                {competitions?.map((el, i) => {
                  return (
                      <div
                        className={`option ${payload.competitions.includes(el) ? "selected" : ""}`}
                        key={`discipline-${i}-${el}`}
                        onClick={() => {
                            if (payload.competitions.includes(el)) {
                                let newPayload = JSON.parse(JSON.stringify(payload))
                                newPayload.competitions.splice(payload.competitions.indexOf(el), 1)
                                setPayload(newPayload)
                            } 
                            else {
                                setPayload({...payload, competitions: [...payload.competitions, el]})
                            }
                        }}
                      >
                        {el}
                      </div>
                  );
                })}
              </div>
            </div>

            <div className="side right">
              <p>Renting out equipment</p>
              <div className="options">
                {equipment.map((el, i) => {
                  return (
                      <div
                      className={`option ${payload.equipment.includes(el) ? "selected" : ""}`}
                      key={`equipment-${el}-${i}`}
                      onClick={() => {
                        if (payload.equipment.includes(el)) {
                            let newPayload = JSON.parse(JSON.stringify(payload))
                            newPayload.equipment.splice(payload.equipment.indexOf(el), 1)
                            setPayload(newPayload)
                        } 
                        else {
                            setPayload({...payload, equipment: [...payload.equipment, el]})
                        }
                    }}
                      >
                        {el}
                      </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {isEdit ?
          <div className="bttn-wrapper mobile">
            <button
            disabled={isDisabled}
            className="bttn primary" 
            onClick={() =>{
              //  setIsClicked(true)
               dispatch(changeTab('team members'))
               dispatch(editTeamMemberAsync({ payload }))
               navigate('/registration')
              }}
            >SAVE</button>
            <button
            className="bttn secondary"
            onClick={() => {
              setIsClicked(true)
              setOpenDelete(true)
              // navigate('/registration')
            }}
            >DELETE</button>
          </div> 
         : <button
          disabled={isDisabled} 
          className={`add-member mobile ${isDisabled ? 'disabled' : ''}`}
          onClick={() => {
            if (isInputValid(payload.fullName)&& isInputValid(payload.address) && isInputValid(payload.email)) {
              dispatch(addTeamMemberAsync({ payload }))
              setIsClicked(true)
              setOpen(true)
            }
          }}>ADD MEMBER</button>}
      </div>

      {isClicked && !isEdit && <AddTeamMember onClick={() => { 
          dispatch(changeTab('team members'))
          navigate('/registration')
          }} />}
      {isClicked && isEdit && 
      <DeleteTeamMember 
        onGoBack={() => {
        setIsClicked(false)
        // dispatch(changeTab('team members'))
        // navigate('/registration')
        }} 

        onDelete={() => {
          dispatch(deleteTeamMemberAsync({ payload }))
          dispatch(changeTab('team members'))
          navigate('/registration')
        }}
        />}


    <Sheet 
    isOpen={open}
    onClose={() => {
      setOpen(false)
      dispatch(changeTab('team members'))
      navigate('/registration')
      }}
    snapPoints={[0.33]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
          <AddTeamMember onClick={() => { 
          dispatch(changeTab('team members'))
          navigate('/registration')
          }} />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
     </Sheet>

    <Sheet isOpen={openDelete} onClose={() => setOpenDelete(false)} snapPoints={[0.33]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
          <DeleteTeamMember 
          onGoBack={() => {
          setOpenDelete(false)
          dispatch(changeTab('team members'))
          navigate('/registration')
          }} 

          onDelete={() => {
            dispatch(deleteTeamMemberAsync({ payload }))
            dispatch(changeTab('team members'))
            navigate('/registration')
          }} />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
     </Sheet>

      <FooterRegistration />
    </div>
  );
}
