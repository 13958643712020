import React from 'react';
import './styles.scss';
import moment from 'moment';
import { Countdown } from '../Countdown';
import { useState, useEffect } from 'react';

export default function RegistrationComingSoon({ finishCounterDate, setIsFinished }) {
  const [ isRegistrationClosed, setIsRegistrationClosed ] = useState(true);
  const closedRegistrationDate = 'November, 21, 2023, 16:18:00';

  useEffect(() => {
    if (Date.parse(closedRegistrationDate) - Date.parse(new Date()) < 0) {
      setIsRegistrationClosed(true)
    }
    else {
      setIsRegistrationClosed(false)
    }
  }, [ isRegistrationClosed ])

  return (
    <div className='registration-counter-wrapper'>
      {!isRegistrationClosed ?
      <div className='card-wrapper'>
        <h1>COMING SOON</h1>

        <Countdown deadline={finishCounterDate} setIsFinished={setIsFinished} />
      </div>
      :
      <div className='card-wrapper'>
        <h1>REGISTRATION IS CLOSED</h1>
        <div className='closed-registration-img'>
          <img src={require("../../assets/images/Main-Screen.png")} />
        </div>
      </div>}



      <img src={require('../../assets/images/mountains-registration.svg').default} />
    </div>
  )
}
