import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableElement } from '../../TableElement'
import { TeamMemberMobileCard } from '../../TeamMemberMobileCard'
import { useSelector, useDispatch } from 'react-redux'
import './styles.scss'
import '../../../screens/Registration/styles.scss'
import { changeTab } from '../../../config/stores'

export default function TeamMembers() {
  const navigate = useNavigate()
  const tableHeaderElements = [
    {name: 'Names', size: 'medium'}, 
    {name: 'Date of birth', size: 'medium'}, 
    {name: 'Address', size: 'large'}, 
    {name: 'Email address', size: 'medium'}, 
    {name: 'Nationality', size: 'medium'}, 
    {name: 'Height', size: 'small'}, 
    {name: 'Shoe size', size: 'small'}, 
    {name: 'T-shirt size', size: 'small'}, 
    {name: 'Competing in', size: 'medium'}, 
    {name: 'Renting out equipment', size: 'large'}
  ]

  const members = useSelector(state => state.registration.members);
  const selectedTab = useSelector(state => state.registration.selectedTab);
  const dispatch = useDispatch();

  let isTeamLeaderExistent = members?.length > 0 && (members?.filter(member => member.isTeamLeader === true).length > 0 ? true : false);
  let isDisabled = !(members?.length >= 2 && isTeamLeaderExistent);

  return (
    <div className="registration second-tab">
    <div className="section">

      <div className="section-top-wrapper">
        <div className='flex mobile' onClick={() => dispatch(changeTab("general information"))}>
          <img src={require('../../../assets/images/arrow-back.svg').default} />
          <h4>Back to general information</h4>
        </div>
   
        <div className='tab center second'>Team members</div>
        <div className="text-wrapper">
          <h1>LIST YOUR TEAM MEMBERS</h1>
          <p>This will be the official list of all the team members. Please note that if you wish to cancel or </p>
        </div>

        <button disabled={isDisabled} onClick={() => dispatch(changeTab("review info"))}>
          CONTINUE
        </button>
      </div>

      <div className={`section-main-wrapper ${members?.length === 0 ? 'empty' : ''}`}>
        {members?.length > 0 && 
        <div className="table-wrapper desktop">
          <div className="table-header-wrapper">
            {tableHeaderElements.map((el, index) => { return (<div className={`header-element ${el.size}`} key={`table-el-${index}`}>{el.name}</div>)})}
          </div>
          
          <TableElement elements={members} />
        </div>}

        {members?.length > 0 && 
          <div className="table-wrapper mobile">
                <TeamMemberMobileCard elements={members} />
          </div>}
        <button className='add' onClick={() => navigate('/registration/team-member')}>ADD A TEAM MEMBER</button>

        {members?.length === 0 && 
        <div className='empty-state-wrapper'>
          <img src={require("../../../assets/images/empty-state.svg").default} />
          <h3>You don’t have anyone added yet</h3>
        </div>}
        
        <button
         className='continue-button-mobile'
         disabled={isDisabled}
         onClick={() => dispatch(changeTab("review info"))}>
          CONTINUE
        </button>
      </div>
    </div>
  </div>
  )
}
