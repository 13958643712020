import React from "react";
import "./styles.scss";

export default function RegistrationPopup({ isMobile }) {
  const data = [
    "First and last name",
    "Date of birth",
    "Address",
    "Email address",
    "Nationality",
    "Height",
    "Shoe and T-shirt size",
    "In which sports will they compete",
    "Will they rent out equipment, if yes - what",
  ];

  return (
    <div className={`popup-wrapper ${isMobile ? 'mobile' : ''}`}>
      <div className="content-wrapper">
        <h4>For each team member you need to provide:</h4>

        <div className="data-wrapper">
          {data.map((el, i) => {
            return (
              <div className="popup-row" key={i + 112}>
                <img src={require("../../assets/images/rectangle-blue.svg").default} />
                <div>{el}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="img-wrapper">
        <img className="info-icon arrow" src={require("../../assets/images/triangle-arrow.png")} />
      </div>
    </div>
  );
}
