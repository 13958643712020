import { configureStore } from '@reduxjs/toolkit'
import registration from "../reducers/registration"

export * from "../reducers/registration"

const store = configureStore({
    reducer: {
        registration,
    },
})

// const createHistory = require('history').createBrowserHistory
// const history = createHistory()


export default store