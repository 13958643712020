import React from 'react'
import './styles.scss'

export default function HeroSection() {
  return (
    <div className='hero-section-wrapper'>
      <div className='text-section'>
        <h1 className='small'>January 11 - 14 2024</h1>
        <h1 className='large'>Bansko, bulgaria</h1>
        <h1 className='medium'>49th golden flight level</h1>
      </div>

      <div className='image-section'>
        <img src={require('../../assets/images/ski-hero-section.svg').default} />
      </div>
    </div>
  )
}
