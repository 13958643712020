import React from "react";
import './styles.scss';
import { useNavigate } from "react-router-dom";

export default function About({ aboutRef }) {
  const navigate = useNavigate();

  return (
    <div className="about-section-wrapper" ref={aboutRef}>
      <img src={require("../../assets/images/ski-about-section.svg").default} />

      <div className="text-section">
        <h1>About gfl ‘24</h1>
        <p>
          Short stories are also a great resource for English learners because
          they allow you to work on reading, speaking and listening at the same
          time. In our fantastic digital age, it is possible to find wonderful
          short stories online in video form. If you find a video that includes
          English-language subtitles, you can read while also listening to how a
          native speaker pronounces words.
        </p>
        <button onClick={() => {
          if (window.innerWidth < 1100) {
            navigate('/registration-info', {state: 'mobile'})
          }
          else {
            window.open('/registration-info')
          }
          }}>Register now</button>
      </div>
    </div>
  );
}
