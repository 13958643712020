import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function Hotels({ hotelsRef }) {
  const [hotelData, setHotelData] = useState([
    {
      name: "Hotel Kempinski grand arena 5*",
      img: require("../../assets/images/hotel-1.png"),
    },
    {
      name: "Green life resort bansko 5*",
      img: require("../../assets/images/hotel-2.png"),
    },
  ]);

  return (
    <div className="hotels-section-wrapper" ref={hotelsRef}>
      <h1>HOTELS</h1>

      <div className="data-hotels-wrapper">
        <div className="text-hotels-wrapper">
        <p>
          The five hotels we have chosen for GALTÜR 2023 are all in 4-star
          category; three are located in Galtür, two in Wirl, with less than two
          kilometers distance between them. Free ski buses, which stop right in
          front of the hotels, assure you get around easily. 
        </p>
        <p>
          The five hotels we
          have chosen for GALTÜR 2023 are all in 4-star category; three are
          located in Galtür, two in Wirl, with less than two kilometers distance
          between them. Free ski buses, which stop right in front of the hotels,
          assure you get around easily.
        </p>
        <p>
          All hotels are reserved exclusively for
          Golden Flight Level. They offer double and triple bedrooms, some
          suites for up to six people, and a limited number of single rooms.
          When assigning the rooms we will focus on keeping the teams together.
        </p>
        <p>All hotels have a hotelbar and a spa and wellness area.</p>
        </div>

        <div className="hotels-wrapper">
          {hotelData.map((hotel, i) => {
            return (
              <div className="hotel" key={`${hotel.name}-${i}`}>
                <img src={hotel.img} />
                <h2>{hotel.name}</h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
