import React from "react";
import "./styles.scss";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

export default function TableElement({ elements }) {
    const navigate = useNavigate();

  return (
    <div className="table-elements-wrapper">
      {elements?.map((el, index) => {
        return (
          <div className="single-member-wrapper" key={`table-member-${index}`}>
            <div className={`single-member ${(index === elements?.length - 1 && !el.isTeamLeader) ? 'last' : ''}`}>
              <div className="element medium">{el.fullName}</div>
              <div className="element medium">{moment(el.birthdate).format('DD.MM.YYYY')}</div>
              <div className="element large">{el.address}</div>
              <div className="element medium">{el.email}</div>
              <div className="element medium">{el.nationality}</div>
              <div className="element small">{el.height}</div>
              <div className="element small">{el.shoeSize} EU</div>
              <div className="element small">{el.shirtSize}</div>

              <div className="element medium start flex">
                <div>{`${el.competitions?.length} ${el.competitions?.length === 1 ? 'discipline' : 'disciplines'}`}</div>
                <img className="info icon" src={require("../../assets/images/info-blue.svg").default} />

                <div className="arrow-data-wrapper">
                  <div className="arrow-wrapper">
                    <img src={require("../../assets/images/triangle-arrow.png")} />
                  </div>

                  <div className="more-data-wrapper">
                <p>Competing in:</p>
                    {el.competitions.map((competition, index) => {
                        return (<div className="info-wrapper" key={`competing-${index}`}>
                            <img src={require("../../assets/images/check-mark.svg").default} />
                            <div className="text">{competition}</div>
                            </div>)
                    })}
                </div>
                </div>
              </div>

              <div className="element large last flex">
                <div className="text">
                {el.equipment?.length === 0 ? 
                <div>— —</div> :
                <>
                  <div>{`${el.equipment?.length} ${el.equipment?.length === 1 ? 'element' : 'elements'}`}</div>
                  <img src={require("../../assets/images/info-blue.svg").default} />
                  {el.equipment?.length > 0 &&
                  <div className="arrow-data-wrapper">
                    <div className="arrow-wrapper">
                    <img src={require("../../assets/images/triangle-arrow.png")} />
                  </div>

                  <div className="more-data-wrapper last">
                      <p>Selected equipment:</p>
                        {el.equipment?.map((equipment, index) => {
                        return (<div className="info-wrapper" key={`equipment-${index}`}>
                            <img src={require("../../assets/images/check-mark.svg").default} />
                            <div className="text">{equipment}</div>
                            </div>)
                    })}
                </div>
                  </div>}
                </>
                }
                </div>

                <img className="edit" src={require("../../assets/images/edit.svg").default}
                  onClick={() => navigate(`/registration/team-member/${el._id}`, {state: 'edit'})}
                 />
              </div>
            </div>
            {el.isTeamLeader && 
            <div className="team-leader">TEAM LEADER</div>}
          </div>
        );
      })}
    </div>
  );
}
