import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { Loading } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { GeneralInformation, TeamMembers, ReviewPaymentInfo } from '../../components/RegistrationTabs';
import { createRegistrationAsync, updateRegistrationAsync, changeTab } from "../../config/stores";

export default function Registration() {

  const selectedTab = useSelector(state => state.registration.selectedTab);
  const state = useSelector(state => state.registration);
  const members = useSelector(state => state.registration.members);
  const isLoading = useSelector(state => state.registration.isLoading);
  const dispatch = useDispatch();

  const [ width, setWidth ] = useState(0);
  const [payload, setPayload] = useState({
    team: "",
    organization: "",
    country: "",
    hotel: "",
    singleRoomCount: 0,
    doubleRoomCount: 0,
    tripleRoomCount: 0
  });

  const checkData = (tab) => {
    let isDisabled = true;
    if (tab === 'general information') {
      isDisabled = payload.team === "" || payload.organization === "" || payload.country === "" || payload.hotel === "" || (payload.doubleRoomCount === 0 && payload.singleRoomCount === 0 && payload.tripleRoomCount === 0);
    }
    else if (tab === 'team members') {
      let isTeamLeaderExistent = members?.length > 0 && (members?.filter(member => member.isTeamLeader === true).length > 0 ? true : false);
      isDisabled = !(members?.length >= 2 && isTeamLeaderExistent);
    }

    return isDisabled
  }

  // const handleWindowSizeChange = (width) => {
  //   setScreenWidth(width)
  // };

  let disabledTabs = [];
  let isTeamLeaderExistent = members?.length > 0 && (members?.filter(member => member.isTeamLeader === true).length > 0 ? true : false);
  let isDisabled = !(members?.length >= 2 && isTeamLeaderExistent);
  let isMobile = width < 1300

  if (payload.team === "" || payload.organization === "" || payload.country === "" || payload.hotel === "" || (payload.doubleRoomCount === 0 && payload.singleRoomCount === 0 && payload.tripleRoomCount === 0)) {
    disabledTabs.push('team members')
  } 
  else {
    disabledTabs.splice(disabledTabs.indexOf('team members'), 1)
  }

  if (isDisabled) {
    disabledTabs.push('review info')
  }
  else {
    disabledTabs.splice(disabledTabs.indexOf('review info'), 1)
  }

  useEffect(() => {
    // window.addEventListener('resize', handleWindowSizeChange(window.innerWidth));
    window.addEventListener('resize', setWidth(window.innerWidth));
    isMobile = width < 1300

    return () => window.removeEventListener('resize', setWidth(window.innerWidth));
  }, [window.innerWidth]);

  return (
    <div className={`registration-page-wrapper ${selectedTab !== '1' ? 'full-height' : ''} ${selectedTab}`}>
      <div className="registration-tabs-wrapper">
        <div
          className={`tab start ${selectedTab === "general information" ? "selected" : ""}`}
          onClick={() => dispatch(changeTab('general information'))}
        >
          General information
        </div>
        <div
          className={`tab center ${selectedTab === "team members" ? "selected" : ""} ${disabledTabs.includes('team members') ? 'disabled' : ''}`}
          onClick={() => { 
            !(disabledTabs.includes('team members')) && dispatch(changeTab('team members')) && 
            (state?.team === '' ? dispatch(createRegistrationAsync({payload})) : dispatch(updateRegistrationAsync({payload})))
          }}
        >
          Team members
        </div>
        <div
          className={`tab end ${selectedTab === "review info" ? "selected" : ""} ${disabledTabs.includes('review info') ? 'disabled' : ''}`}
          onClick={() => {
            !(disabledTabs.includes('review info')) && dispatch(changeTab('review info'))
            && dispatch(updateRegistrationAsync({payload}))
          }}
        >
          Review and payment info
        </div>
      </div>

      {selectedTab === "general information" && <GeneralInformation onChange={(value) => setPayload(value)} />}

      {selectedTab === "team members" && <TeamMembers />}

      {selectedTab === "review info" && <ReviewPaymentInfo />}

      {isLoading && <Loading />}
      
    </div>
  );
}
