import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function Schedule({ scheduleRef }) {
  const [option, setOption] = useState("1");

  return (
    <div className="schedule-section-wrapper" ref={scheduleRef}>
      <div className="mobile-wrapper">
        <h1 className="main-heading">Event timeline</h1>

        <div className="events-wrapper">
          <div className="section">
            <div className="section-title">2023 - registration</div>

            <div className="data-icons-wrapper">
              <div className="line-wrapper">
                <img
                  src={require("../../assets/images/ombre-line.svg").default}
                  className="line"
                />
              </div>

              <div className="data-wrapper">
                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">MAY 15th – 25th</div>
                    <div className="light">Registration window for banks 2024</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">Until June 1st</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 15th</div>
                    <div className="light">First payment due - 480 EUR</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 20th – jULY 20th</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 2th – 4th</div>
                    <div className="light">PRE-GFL in Munich</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 15th</div>
                    <div className="light">Second payment due - 300 EUR</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">DECEMBER 15th</div>
                    <div className="light">Third payment due - 610 EUR</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">2024 - plovdiv event</div>

            <div className="data-icons-wrapper">
              <div className="line-wrapper">
                <img
                  src={require("../../assets/images/ombre-line.svg").default}
                  className="line"
                />
              </div>

              <div className="data-wrapper">
                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">MAY 15th – 25th</div>
                    <div className="light">Registration window for banks 2024</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">Until June 1st</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 15th</div>
                    <div className="light">First payment due - 480 EUR</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 20th – jULY 20th</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 2th – 4th</div>
                    <div className="light">PRE-GFL in Munich</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 15th</div>
                    <div className="light">Second payment due - 300 EUR</div>
                  </div>
                </div>

                <div className="row last">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">DECEMBER 15th</div>
                    <div className="light">Third payment due - 610 EUR</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">2024 - official gfl</div>

            <div className="data-icons-wrapper">
              <div className="line-wrapper">
                <img
                  src={require("../../assets/images/ombre-line.svg").default}
                  className="line"
                />
              </div>

              <div className="data-wrapper">
                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">MAY 15th – 25th</div>
                    <div className="light">Registration window for banks 2024</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">Until June 1st</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 15th</div>
                    <div className="light">First payment due - 480 EUR</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">JUNE 20th – jULY 20th</div>
                    <div className="light">Slot allocation for GALTÜR 2023</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 2th – 4th</div>
                    <div className="light">PRE-GFL in Munich</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">OCTOBER 15th</div>
                    <div className="light">Second payment due - 300 EUR</div>
                  </div>
                </div>

                <div className="row">
                  <img
                    src={require("../../assets/images/blue-rectangle.svg").default}
                    className="rectangle"
                  />
                  <div className="text-wrapper">
                    <div className="bold">DECEMBER 15th</div>
                    <div className="light">Third payment due - 610 EUR</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-wrapper">
        <h1>Event timeline</h1>

        <div className="events-wrapper">
          <div
            className={`option first ${option === "1" ? "selected" : ""}`}
            onClick={() => setOption("1")}
          >
            2023 - REGISTRATION
          </div>
          <div
            className={`option ${option === "2" ? "selected" : ""}`}
            onClick={() => setOption("2")}
          >
            2024 - PLOVDIV EVENT
          </div>
          <div
            className={`option last ${option === "3" ? "selected" : ""}`}
            onClick={() => setOption("3")}
          >
            2024 - OFFICIAL GFL
          </div>
        </div>

        <div className="schedule-wrapper">
          <div className="column left">
            <div className="row bold first">MAY 15th – 25th</div>
            <div className="row light">Slot allocation for GALTÜR 2023</div>
            <div className="row bold">JUNE 15th</div>
            <div className="row light">Slot allocation for GALTÜR 2023</div>
            <div className="row bold">OCTOBER 2th – 4th</div>
            <div className="row light">Second payment due - 300 EUR</div>
            <div className="row bold">DECEMBER 15th</div>
          </div>

          <div className="column center">
            <img
              src={require("../../assets/images/ombre-line.svg").default}
              className="line"
            />
            {Array(7)
              .fill("")
              .map((el, i) => {
                return (
                  <img
                    src={
                      require("../../assets/images/blue-rectangle.svg").default
                    }
                    className={`rectangle ${i === 0 ? "first" : ""}`}
                    key={`${i + 100}`}
                  />
                );
              })}
          </div>

          <div className="column right">
            <div className="row light first">
              Registration window for banks 2024
            </div>
            <div className="row bold">Until June 1st</div>
            <div className="row light">First payment due - 480 EUR</div>
            <div className="row bold">JUNE 20th – JULY 20th</div>
            <div className="row light">PRE-GFL in Munich</div>
            <div className="row bold">OCTOBER 15th</div>
            <div className="row light">Third payment due - 610 EUR</div>
          </div>
        </div>

        <p className="note-bottom">
          Please note that all dates could changed due to the Covid-19 pandemic.
        </p>
      </div>
    </div>
  );
}
