import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

export default function FooterDownloads() {
    const navigate = useNavigate();

  return (
    <div className='footer-wrapper downloads'>
        <div className='section top nav'>
            <li onClick={() => navigate('/')}>About GFL</li>
            <li onClick={() => navigate('/')}>Schedule</li>
            <li onClick={() => navigate('/')}>PACKAGE</li>
            <li onClick={() => navigate('/')}>Transportation</li>
            <li onClick={() => navigate('/')}>Hotels</li>
            <li onClick={() => navigate('/')}>Contact us</li>
            <li onClick={() => navigate('/')}>Past gFLs</li>

            <button onClick={() => navigate('/registration-info', {state: 'mobile'})}>Registration</button>
        </div>
      <div className='section top'>
        <div>DATA PROTECTION POLICY</div>
        <div>IMPRINT-DISCLAIMER</div>
        <div onClick={() => navigate('/downloads')}>DOWNLOADS</div>
      </div>

      <div className='section bottom'>
        <img src={require("../../assets/images/facebook.svg").default} />
        <img src={require("../../assets/images/instagram.svg").default} />
      </div>
    </div>
  )
}
