import React from "react";
import "./styles.scss";

export default function AddTeamMember({ onClick, isMobile }) {
  return (
    <div className={`modal-wrapper add ${isMobile ? 'mobile' : ''}`}>
      <div className="content-wrapper">
        <div className="top-wrapper">
          <img
            src={require("../../../assets/images/success-check.svg").default}
          />
          <h1>Successfully added</h1>
          <p>Congrats! This team member was successfully added to the team!</p>
        </div>

        <button onClick={onClick}>Continue</button>
      </div>
    </div>
  );
}
