import React from "react";
import "./styles.scss";
import moment from "moment";

export default function TeamMemberPopup({ isMobile, member }) {
  return (
    <div className='info-wrapper popup'>
      <div className="row">
        <div className="label">First and last name</div>
        <div className="bold">{member.fullName}</div>
      </div>

      <div className="row">
        <div className="label">Date of birth</div>
        <div className="bold">
          {moment(member.birthdate).format("DD.MM.YYYY")}
        </div>
      </div>

      <div className="row">
        <div className="label">Address</div>
        <div className="bold">{member.address}</div>
      </div>

      <div className="row">
        <div className="label">Email address</div>
        <div className="bold">{member.email}</div>
      </div>

      <div className="row">
        <div className="label">Nationality</div>
        <div className="bold">{member.nationality}</div>
      </div>

      <div className="row">
        <div className="label">Height</div>
        <div className="bold">{member.height} cm</div>
      </div>

      <div className="row">
        <div className="label">Shoe size</div>
        <div className="bold">{member.shoeSize} EU</div>
      </div>

      <div className="row">
        <div className="label">T-shirt size</div>
        <div className="bold">{member.shirtSize}</div>
      </div>

      <div className="row">
        <div className="label">Competing in</div>
        <div className="bold">{member?.competitions.join("; ")}</div>
        {/* <div className="bold">Ski; Ski-running</div> */}
      </div>

      <div className="row">
        <div className="label">Renting out equipment</div>
        <div className="bold">{member?.equipment.join("; ") || "— —"}</div>
      </div>
    </div>
  );
}
