import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export default function Package({ packageRef }) {
  const data = [
    "7 nights in 4* accommodation (two and three beds in a room)",
    "HalfboarD",
    "Skipass for Galtür 5 out of 6 days",
    "3 races (XC, GS and SB)",
    "Opening and closing ceremony, theme party",
    "OC fee included: 210 EUR per person (non refundable)",
  ];
  const navigate = useNavigate();

  return (
    <div className="package-section-wrapper" ref={packageRef}>
      <h1>PACKAGE</h1>

      <div className="main-content">
        <div className="section left">
          <h1><span>1.390 EUR</span> per person</h1>

          <div className="list-wrapper">
            {data.map((el) => {
              return (
                <div className="list-element" key={el}>
                  <img src={require("../../assets/images/check-mark.svg").default}/>
                  <div>{el}</div>
                </div>
              );
            })}

            <div className="list-element">
              <img src={require("../../assets/images/remove-icon.svg").default} />
              <div>single room: additional 105 EUR (limited availability)</div>
            </div>
          </div>
        </div>

        <div className="section right">
          <h1>Register your team and hit the slopes</h1>
          <p>
            Create a profile and register your team to participate in GFL 2024
          </p>

          <button
            onClick={() => {
                  if (window.innerWidth < 1100) {
                    navigate('/registration-info', {state: 'mobile'})
                  }
                  else {
                    window.open('/registration-info')
                  }
          }}>Register now</button>

          <img src={require("../../assets/images/snow.png")} />
        </div>
      </div>
    </div>
  );
}
