import React from "react";
import "./styles.scss";

export default function Contact({ contactRef }) {
  return (
    <div className="contact-section-wrapper" ref={contactRef}>
      <div className="img-wrapper">
        <img className="large-illustration" src={require("../../assets/images/ski-contact-section.png")}/>
      </div>

      <div className="data-wrapper">
        <h1>Contact us</h1>
        <p>Our team is here for you, so do not hesitate to reach out!</p>

        <div className="contacts-info-wrapper">
          <div className="contacts light">
            <div className="contact-row line">Email:&nbsp; <span> info@gfl2024.com</span></div>
            <div className="contact-row">Phone:&nbsp; <span> +359 000 888 999</span></div>
          </div>

          <div className="contacts dark">
            <div className="contact-row line">
                <img src={require('../../assets/images/facebook.svg').default} />:&nbsp; <span>Official GFL 2024</span>
            </div>
            <div className="contact-row">
                <img src={require('../../assets/images/instagram.svg').default}/>:&nbsp; <span>Official GFL 2024</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
