import axios from 'axios';
import { API_URL } from '../config/settings'

const httpClient = axios.create({ baseURL: API_URL });

// const httpClient = axios.create({ baseURL: API_URL, headers: { 
//     'x-apikey': '59a7ad19f5a9fa0808f11931',
//     'Access-Control-Allow-Origin' : '*',
//     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//   } });

// httpClient.interceptors.request.use(
//     async (config) => {
//         config.headers = { 
//             'Access-Control-Allow-Origin': '*',
//             'Access-Control-Allow-Headers': '*',
//             'Access-Control-Allow-Credentials': 'true',
//             "Content-Type": "application/json",
//             'cors': 'true'
//          }
//         return config
//     },
//     (error) => { Promise.reject(error) }
// )

httpClient.interceptors.response.use(
    function (response) { return response },
    function (error) {
        // store.dispatch(stopLoading())
        const errorMessage = error?.response?.data?.message;
        // if (errorMessage) Alerts.error({ text: errorMessage })
        if (errorMessage) alert(errorMessage)
        return Promise.reject(error)
    });

export default httpClient;