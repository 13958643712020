import React from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className='footer-wrapper'>
      <div className='section top'>
        <div>DATA PROTECTION POLICY</div>
        <div>IMPRINT-DISCLAIMER</div>
        <div onClick={() => navigate('/downloads')}>DOWNLOADS</div>
      </div>

      <div className='section bottom'>
        <img src={require("../../assets/images/facebook.svg").default} />
        <img src={require("../../assets/images/instagram.svg").default} />
      </div>
    </div>
  )
}
