import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { completeRegistrationAsync, changeTab, setLoading } from "../../../reducers/registration";
import moment from "moment";
import Sheet from 'react-modal-sheet';
import { TeamMemberPopup } from "../../TeamMemberPopup";

export default function ReviewPaymentInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const registrationInfo = useSelector(state => state.registration);
  const members = useSelector(state => state.registration.members);
  const [ open, setOpen ] = useState(false);
  const [ member, setMember ] = useState([]);

  return (
    <div className="registration third-tab">
      <div className='section'>
        <div className="section-top-wrapper">
        <div className='flex mobile' onClick={() => dispatch(changeTab("team members"))}>
          <img src={require('../../../assets/images/arrow-back.svg').default} />
          <h4>Back to team members</h4>
        </div>
        <div className='tab center'>Review and payment info</div>
          <div className="text-wrapper">
            <h1>REGISTRATION SUMMARY</h1>
            <p>
              Please go over all the information before you submit your
              registration. Your team leader will receive a confirmation email
              with all the details as well as payment information
            </p>
          </div>

          <button className="submit desktop" onClick={() => {
            dispatch(completeRegistrationAsync({ registrationInfo }))
            dispatch(changeTab('general information'))
            dispatch(setLoading(true))
            navigate('/registration-info', {state: 'success', replace: 'true'})
          }}>SUBMIT REGISTRATION</button>
        </div>

        <div className="section-main-wrapper">
          <div className="section left">
            <div className="section-info top">
              <h2>GENERAL INFORMATION</h2>

              <div className="info-wrapper padding">
                <div className="row">
                  <div className="label">Team’s name</div>
                  <div className="bold">{registrationInfo.team}</div>
                </div>

                <div className="row">
                  <div className="label">Organisation</div>
                  <div className="bold">{registrationInfo.organization}</div>
                </div>

                <div className="row">
                  <div className="label">Country</div>
                  <div className="bold">{registrationInfo.country}</div>
                </div>

                <div className="row">
                  <div className="label">Hotel type</div>
                  <div className="bold">{registrationInfo.hotel}</div>
                </div>

                <div className="row">
                  <div className="label">Double rooms</div>
                  <div className="bold">{registrationInfo.doubleRoomCount}</div>
                </div>

                <div className="row">
                  <div className="label">Triple rooms</div>
                  <div className="bold">{registrationInfo.tripleRoomCount}</div>
                </div>

                <div className="row">
                  <div className="label">Single rooms</div>
                  <div className="bold">{registrationInfo.singleRoomCount}</div>
                </div>
              </div>
            </div>

            <div className="section-info bottom desktop">
              <h2>TEAM MEMBERS</h2>

              {members?.map((member, memberIndex) => {
                return (
              <div 
              key={`member-info-${memberIndex}`}
              className={`info-wrapper ${memberIndex !== members?.length - 1 ? 'margin' : ''}`}>
                <div className="row">
                  <div className="label">First and last name</div>
                  <div className="bold">{member.fullName}</div>
                </div>

                <div className="row">
                  <div className="label">Date of birth</div>
                  <div className="bold">{moment(member.birthdate).format('DD.MM.YYYY')}</div>
                </div>

                <div className="row">
                  <div className="label">Address</div>
                  <div className="bold">{member.address}</div>
                </div>

                <div className="row">
                  <div className="label">Email address</div>
                  <div className="bold">{member.email}</div>
                </div>

                <div className="row">
                  <div className="label">Nationality</div>
                  <div className="bold">{member.nationality}</div>
                </div>

                <div className="row">
                  <div className="label">Height</div>
                  <div className="bold">{member.height} cm</div>
                </div>

                <div className="row">
                  <div className="label">Shoe size</div>
                  <div className="bold">{member.shoeSize} EU</div>
                </div>

                <div className="row">
                  <div className="label">T-shirt size</div>
                  <div className="bold">{member.shirtSize}</div>
                </div>

                <div className="row">
                  <div className="label">Competing in</div>
                  <div className="bold">{member?.competitions.join('; ')}</div>
                  {/* <div className="bold">Ski; Ski-running</div> */}
                </div>

                <div className="row">
                  <div className="label">Renting out equipment</div>
                  <div className="bold">{member?.equipment.join('; ') || '— —'}</div>
                </div>
              </div>)
              })}
            </div>

            <div className="section-info bottom mobile">
              <h2>Team members</h2>
            {members?.map((member, memberIndex) => {
                return (
              <div 
              key={`member-info-${memberIndex}`}
              className={`info-wrapper ${memberIndex !== members?.length - 1 ? 'margin' : ''}`}>
              <div className="flex">
                <div className="row first">{member.fullName}</div>
                <div className="row last">{member.email}</div>
              </div>

              <img src={require('../../../assets/images/info-blue.svg').default} 
                  onClick={() => {
                    setOpen(true)
                    setMember(member)
                  }} />
              </div>)
              })}
            </div>
          </div>

          <div className="section right">
            <div className="section-info top">
              <h2>THREE SEPARATE PAYMENTS</h2>

              <div className="info-wrapper border padding">
                <div className="row">
                  <img
                    className="icon blue"
                    src={
                      require("../../../assets/images/rectangle-blue.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    First payment:{" "}
                    <span className="bold">480 EUR (including OC fee)</span> -
                    due by 15 June 2022{" "}
                  </div>
                </div>

                <div className="row">
                  <img
                    className="icon blue"
                    src={
                      require("../../../assets/images/rectangle-blue.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    Second payment: <span className="bold">300 EUR</span> - due
                    by 15 October 2022{" "}
                  </div>
                </div>

                <div className="row">
                  <img
                    className="icon blue"
                    src={
                      require("../../../assets/images/rectangle-blue.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    Third payment: <span className="bold">610 EUR</span> - due
                    by 15 December 2022{" "}
                  </div>
                </div>
              </div>

              <div className="info-wrapper padding">
                <div className="extra-text">
                  You will receive information regarding all payments via email.
                  All payments must be made to:
                </div>
              </div>
            </div>

            <div className="section-info bottom">
              <h2>CANCELATION FEES</h2>

              <div className="info-wrapper border padding">
                <div className="row">
                  <img
                    className="icon black"
                    src={
                      require("../../../assets/images/rectangle-black.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    Until October 15th:{" "}
                    <span className="bold">210 EUR OC fee</span>
                  </div>
                </div>
                <div className="row">
                  <img
                    className="icon black"
                    src={
                      require("../../../assets/images/rectangle-black.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    Until November 15th: <span className="bold">510 EUR</span>
                  </div>
                </div>
                <div className="row">
                  <img
                    className="icon black"
                    src={
                      require("../../../assets/images/rectangle-black.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    until December 15th: <span className="bold">640 EUR</span>
                  </div>
                </div>
                <div className="row">
                  <img
                    className="icon black"
                    src={
                      require("../../../assets/images/rectangle-black.svg")
                        .default
                    }
                  />
                  <div className="medium">
                    After December 15th: <span className="bold">no refund</span>
                  </div>
                </div>
              </div>

              <div className="info-wrapper padding">
                <div className="extra-text">
                  If you wish to cancel and have found somebody to take over
                  your spot (from your own team or from a potential waiting
                  list) no cancellation charges apply.
                </div>
              </div>
            </div>
          </div>

          <button className="submit mobile" onClick={() => {
            dispatch(completeRegistrationAsync({ registrationInfo }))
            dispatch(changeTab('general information'))
            dispatch(setLoading(true))
            navigate('/registration-info', {state: 'success', replace: 'true'})
          }}>SUBMIT REGISTRATION</button>
        </div>
      </div>

      <Sheet isOpen={open} onClose={() => setOpen(false)} snapPoints={[0.87]}>
                      <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content>
                          <TeamMemberPopup isMobile={true} member={member} />
                        </Sheet.Content>
                      </Sheet.Container>
              
                      <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}
