import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

export default function TeamMemberMobileCard({ elements }) {
    const navigate = useNavigate();
    
  return (
    <div className='team-member-mobile-cards-wrapper'>
      {elements?.map((member, index) => {
        return (
            <div className='team-member-single-card-wrapper'>
            <div className='text-wrapper'>
              {member?.isTeamLeader && <div className='element blue'>Team Leader</div>}
              <div className="element text">{member.fullName}</div>
              <div className="element text">{member.email}</div>
            </div>
            
            <img src={require("../../assets/images/edit.svg").default} onClick={() => navigate(`/registration/team-member/${member._id}`, {state: 'edit'})} />

        </div>
        )
      })}
    </div>
  )
}
