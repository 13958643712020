import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import './styles.scss';
import '../../../screens/Registration/styles.scss';
import { createRegistration } from '../../../reducers/registration';
import httpClient from '../../../epics/registration';
import { isInputValid } from '../../../config/utilities';
import { createRegistrationAsync, updateRegistrationAsync, changeTab, resetState } from '../../../reducers/registration';

export default function GeneralInformation({ onChange }) {
  const selectedTab = useSelector(state => state.registration.selectedTab);
  const state = useSelector(state => state.registration)
  const [payload, setPayload] = useState({
    team: "",
    organization: "",
    country: "",
    hotel: "",
    singleRoomCount: 0,
    doubleRoomCount: 0,
    tripleRoomCount: 0
  });
  const [ focusedElement, setFocusedElement ] = useState(false);
  const [ secondFocusedElement, setSecondFocusedElement ] = useState(false);
  const [ error, setError ] = useState(false);
  // const [ countryQuery, setCountryQuery ] = useState('');

  const dispatch = useDispatch();

  let isDisabled = payload.team === "" || payload.organization === "" || payload.country === "" || payload.hotel === "" || (payload.doubleRoomCount === 0 && payload.singleRoomCount === 0 && payload.tripleRoomCount === 0);
  let countryQuery = '';
  let hotelQuery = '';

  const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russia",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  ];
  const hotelList = ["Hotel 1", "Hotel 2", "Hotel 3"];

//   const handleClick = async () => {
//     try {
//       await state?.team === '' ? dispatch(createRegistrationAsync({payload})) : dispatch(updateRegistrationAsync({payload}))
//       // await dispatch(changeTab("team members"));
//     } catch(error) {
//         setError(true);
//         dispatch(changeTab("general information"))
//     }
// }

console.log('payload', payload)

  useEffect(() => {
    setPayload({
      team: state.team,
      organization: state.organization,
      country: state.country,
      hotel: state.hotel,
      singleRoomCount: state.singleRoomCount,
      doubleRoomCount: state.doubleRoomCount,
      tripleRoomCount: state.tripleRoomCount
    })

    if (state?.isCompleted) {
      dispatch(resetState());
    }
  }, []);

  useEffect(() => {
    onChange(payload)
  }, [payload])

  return (
  <div className="registration first-tab">
    <div className="section">
      <div className="section-top-wrapper">
        <div className='tab center'>General information</div>

        <div className="text-wrapper">
          <h1>ABOUT THE TEAM</h1>
          <p>Please list the general information regarding the team</p>
        </div>

        <button disabled={isDisabled} onClick={() => {
          if (isInputValid(payload.team) && isInputValid(payload.organization)) {
            state?.team === '' ? dispatch(createRegistrationAsync({payload})) : dispatch(updateRegistrationAsync({payload}))
            dispatch(changeTab("team members"));
          }
          }}>
          CONTINUE
        </button>
      </div>

      <div className="section-main-wrapper">
        <div className="input-wrapper large">
          <p>
            Team’s name <span className="red">*</span>
          </p>
          <input
            value={payload.team}
            onChange={(e) =>
              setPayload({ ...payload, team: e.target.value })
            }
          ></input>
        </div>

        <div className="input-wrapper large">
          <p>
            Organisation <span className="red">*</span>
          </p>
          <input
            value={payload.organization}
            onChange={(e) =>
              setPayload({ ...payload, organization: e.target.value })
            }
          ></input>
        </div>

        <div className="input-wrapper large">
          <p>
            Country <span className="red">*</span>
          </p>

          <div className="input-arrow-wrapper">
            <input
            type="text"
            value={payload.country}
            onClick={() => setFocusedElement(!focusedElement)}
            onChange={(e) => setPayload({...payload, country: e.target.value})}
            onBlur={() => {
              setTimeout(() => {
                if (!countryList.includes(countryQuery)) {
                  countryQuery = ''
                  setPayload({...payload, country: ''})
                }
                setFocusedElement(false);
              }, 150);
            }}>
              
            </input>

            <img
              className={`arrow ${focusedElement ? 'selected' : ''}`}
              src={require("../../../assets/images/blue-arrow.svg").default}
              onClick={() => setFocusedElement(!focusedElement)}
            />

            {focusedElement && (
              <div className="dropdown-wrapper">
                {countryList
                .filter(el => el.toLowerCase().includes(payload.country.toLowerCase()))
                .map((el, i) => {
                  return (
                    <div
                      key={i + 103}
                      className="dropdown-element"
                      onClick={() => {
                        setPayload({ ...payload, country: el });
                        countryQuery = el;
                        setFocusedElement(false);
                      }}
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    <div className="section">
      <div className="section-top-wrapper">
        <div className="text-wrapper">
          <h1>ACCOMODATION</h1>
          <p>
            Please select the type of hotel you wish to be accommodated in
            and also the type and number of rooms needed{" "}
          </p>
        </div>
      </div>

      <div className="section-main-wrapper">
        <div className="input-wrapper large">
          <p>
            Hotel type <span className="red">*</span>
          </p>

          <div className="input-arrow-wrapper">
            <input
            type="text"
            value={payload.hotel}
            onClick={() => setSecondFocusedElement(!secondFocusedElement)}
            onChange={(e) => setPayload({...payload, hotel: e.target.value})}
            onBlur={() => {
              setTimeout(() => {
                if (!hotelList.includes(hotelQuery)) {
                  hotelQuery = ''
                  setPayload({...payload, hotel: ''})
                }
                setSecondFocusedElement(false);
              }, 150);
            }}>
            </input>
            <img
              className={`arrow ${secondFocusedElement ? 'selected' : ''}`}
              src={require("../../../assets/images/blue-arrow.svg").default}
              onClick={() =>
                setSecondFocusedElement(!secondFocusedElement)
              }
              onBlur={() => {
                setTimeout(() => {
                  setSecondFocusedElement(false);
                }, 150);
              }}
            />

            {secondFocusedElement && (
              <div className="dropdown-wrapper">
                {hotelList
                .filter(el => el.toLowerCase().includes(payload.hotel.toLowerCase()))
                .map((el, i) => {
                  return (
                    <div
                      key={i + 104}
                      className="dropdown-element"
                      onClick={() => {
                        setPayload({ ...payload, hotel: el });
                        hotelQuery = el;
                        setSecondFocusedElement(false);
                      }}
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="input-wrapper small">
          <p>Double room</p>
          <div className="counter-box-wrapper">
            <img
              className={`icon ${payload.doubleRoomCount > 0 ? 'active' : ''}`}
              src={payload.doubleRoomCount > 0 ? 
                (require("../../../assets/images/minus-icon-bold.svg").default) :
                (require("../../../assets/images/minus-icon.svg").default)}
              onClick={() => {
                if (payload.doubleRoomCount) {
                  let newValue = payload.doubleRoomCount - 1;
                  setPayload({
                    ...payload,
                    doubleRoomCount: newValue,
                  });
                }
              }}
            />
            <p>{payload.doubleRoomCount}</p>
            <img
              className='icon'
              src={require("../../../assets/images/plus-icon.png")}
              onClick={() => {
                if (payload.doubleRoomCount < 20) {
                  let newValue = payload.doubleRoomCount + 1;
                  setPayload({
                    ...payload,
                    doubleRoomCount: newValue
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="input-wrapper small">
          <p>Triple room</p>
          <div className="counter-box-wrapper">
            <img
              className={`icon ${payload.tripleRoomCount > 0 ? 'active' : ''}`}
              src={payload.tripleRoomCount > 0 ? 
                (require("../../../assets/images/minus-icon-bold.svg").default) :
                (require("../../../assets/images/minus-icon.svg").default)}
              onClick={() => {
                if (payload.tripleRoomCount) {
                  let newValue = payload.tripleRoomCount - 1;
                  setPayload({
                    ...payload,
                    tripleRoomCount: newValue
                  });
                }
              }}
            />
            <p>{payload.tripleRoomCount}</p>
            <img
              className='icon'
              src={require("../../../assets/images/plus-icon.png")}
              onClick={() => {
                if (payload.tripleRoomCount < 20) {
                  let newValue = payload.tripleRoomCount + 1;
                  setPayload({
                    ...payload,
                    tripleRoomCount: newValue
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="input-wrapper small">
          <p>Single room</p>
          <div className="counter-box-wrapper">
            <img
              className={`icon ${payload.singleRoomCount > 0 ? 'active' : ''}`}
              src={payload.singleRoomCount > 0 ? 
                (require("../../../assets/images/minus-icon-bold.svg").default) :
                (require("../../../assets/images/minus-icon.svg").default)}
              onClick={() => {
                if (payload.singleRoomCount) {
                  let newValue = payload.singleRoomCount - 1;
                  setPayload({
                    ...payload,
                    singleRoomCount: newValue
                  });
                }
              }}
            />
            <p>{payload.singleRoomCount}</p>
            <img
              className='icon'
              src={require("../../../assets/images/plus-icon.png")}
              onClick={() => {
                if (payload.singleRoomCount < 20) {
                  let newValue = payload.singleRoomCount + 1;
                  setPayload({
                    ...payload,
                    singleRoomCount: newValue
                  });
                }
              }}
            />
          </div>

          <p className="extra-info">
            Extra charge applies: <span className="bold">105 €</span>
          </p>
        </div>
      </div>
    </div>

    <button className='button mobile' disabled={isDisabled} onClick={() => {
          dispatch(changeTab("team members"));
          state?.team === '' ? dispatch(createRegistrationAsync({payload})) : dispatch(updateRegistrationAsync({payload}))
          }}>
          CONTINUE
    </button>
  </div>
  )
}
