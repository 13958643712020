import React from "react";
import "./styles.scss";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const [ isNavOpen, setIsNavOpen ] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header-wrapper">
      <div className="mobile">
        <div className={`nav ${isNavOpen ? 'opened' : ''}`}>
          {!isNavOpen &&
          <img 
          src={require('../../assets/images/responsive-menu.svg').default}
          onClick={() => setIsNavOpen(true)} />}

          {isNavOpen && 

          <>
          <img src={require('../../assets/images/blue-delete-icon.png')}
          onClick={() => setIsNavOpen(false)} />

          <div className="opened-nav">
            <li onClick={() => props.scroll(props.aboutRef)}>About GFL</li>
            <li onClick={() => props.scroll(props.scheduleRef)}>Schedule</li>
            <li onClick={() => props.scroll(props.preGFLRef)}>PRE-GFL</li>
            <li onClick={() => props.scroll(props.packageRef)}>PACKAGE</li>
            <li onClick={() => props.scroll(props.transportationRef)}>Transportation</li>
            <li onClick={() => props.scroll(props.hotelsRef)}>Hotels</li>
            <li onClick={() => props.scroll(props.contactRef)}>Contact us</li>
            <li onClick={() => props.scroll(props.eventsRef)}>Past gFLs</li>

            {/* <button onClick={() => window.open('/registration-info')}>Registration</button> */}
            <button onClick={() => navigate('/registration-info', {state: 'mobile'})}>Registration</button>
          </div>
          </>}
        </div>

        {isNavOpen && <div className="background-wrapper"></div>}
        {!isNavOpen && <img className='logo' src={require('../../assets/images/logo.png')} />}
      </div>

      <div className="header-section">
        <ul className="nav">
          <li onClick={() => props.scroll(props.aboutRef)}>About GFL</li>
          <li onClick={() => props.scroll(props.scheduleRef)}>Schedule</li>
          <li onClick={() => props.scroll(props.preGFLRef)}>PRE-GFL</li>
          <li onClick={() => props.scroll(props.packageRef)}>PACKAGE</li>
          <li onClick={() => props.scroll(props.transportationRef)}>Transportation</li>
        </ul>
      </div>

      <div className="header-section">
        <img src={require('../../assets/images/logo.png')} onClick={() => navigate('/')} />
      </div>

      <div className="header-section">
        <ul className="nav">
          <li onClick={() => props.scroll(props.hotelsRef)}>Hotels</li>
          <li onClick={() => props.scroll(props.contactRef)}>Contact us</li>
          <li onClick={() => props.scroll(props.eventsRef)}>Past gFLs</li>
        </ul>

        <div className="button-wrapper">
          <button onClick={() => window.open('/registration-info')}>Registration</button>
        </div>
      </div>

    </div>
  );
}
