import React from 'react'
import './styles.scss'

export default function HeaderRegistration({ registered }) {
  const logo = require('../../assets/images/logo.png');
  
  return (
    <div className='header-wrapper registration'>
      <img src={logo} className='logo'/>
      <div className='center'>{registered ? 'SUCCESSFUL REGISTRATION' : 'TEAM REGISTRATION'}</div>
    </div>
  )
}
