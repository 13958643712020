import React from 'react'
import { HeaderRegistration, FooterRegistration } from '../../components'
import { RegistrationInfo } from '../RegistrationInfo'
import { Registration } from '../Registration'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'

export default function RegistrationPage() {
  const [ continued, setContinued ] = useState(false);
  const selectedTab = useSelector(state => state.registration.selectedTab)
  const navigate = useNavigate();

  const onStart = () => {
    setContinued(true)
  }

  return (
    <div className={`registration-initial-page-wrapper start ${selectedTab}`}>
      <HeaderRegistration />
      {/* {continued ? 
      <Registration /> :
      <RegistrationInfo />} */}
      
      <Registration />
      <FooterRegistration />
    </div>
  )
}
