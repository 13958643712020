import React from "react";
import "./styles.scss";

export default function FooterRegistration() {
  return (
    <div className="footer-wrapper registration">
      <div className="contacts">
        <div className="contact line">
          Email:&nbsp; <span> info@gfl2024.com</span>
        </div>
        <div className="contact">
          Phone:&nbsp; <span> +359 000 888 999</span>
        </div>
      </div>
    </div>
  );
}
