import React from 'react'
import './styles.scss'

export default function PastEvents({ eventsRef }) {
  return (
    <div className='past-events-wrapper' ref={eventsRef}>
      <h1>Past gFLs</h1>

      <div className='img-sections'>
        <div className='section left'>
          <div className='row'>
           <div className='image-wrapper'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-1.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia</div>
            </div>
           </div>

           <div className='image-wrapper'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-2.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>
          </div>

          <div className='image-wrapper'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-3.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>
        </div>

        <div className='section right'>
        <div className='column'>
        <div className='image-wrapper'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-4.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>

           <div className='image-wrapper'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-5.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>
        </div>

        <div className='column'>
        <div className='image-wrapper column'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-6.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>

           <div className='image-wrapper column'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-7.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>

           <div className='image-wrapper column'>
            <div className='photo-wrapper'>
             <img className='photo' src={require('../../assets/images/event-img-8.png')}/>
            </div>
    
            <div className='hovered'>
              <img className='hover-logo' src={require('../../assets/images/logo.png')} />
              <div className='hover-text bold'>42nd GFL - 2017</div>
              <div className='hover-text light'>Kopaonik - Serbia </div>
            </div>
           </div>
        </div>
      </div>
      </div>
    </div>
  )
}
