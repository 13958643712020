import React from "react";
import "./styles.scss";

export default function DeleteTeamMember({ onGoBack, onDelete, isMobile }) {
  return (
    <div className={`modal-wrapper delete ${isMobile ? 'mobile' : ''}`}>
      <div className="content-wrapper">
        <div className="top-wrapper">
          <img src={require("../../../assets/images/delete-alert.svg").default} />
          <h1>Deleting a member</h1>
          <p>Are you sure you want to delete this team member?</p>
        </div>

        <div className="bttn-wrapper">
          <button className="modal-bttn blue" onClick={onGoBack}>Go back</button>
          <button className="modal-bttn red" onClick={onDelete}>Delete</button>
        </div>
      </div>
    </div>
  );
}
