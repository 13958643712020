export const isInputValid = (value) => {
    let spaces = value?.trim().length === 0 || false
    let reg = (/[A-Za-z0-9]{2,}/g)
    if (value.length < 2 || spaces) {
      return alert("Input fields must include at least 2 characters")
    } 
    // if (!reg.test(value) || value === '' || spaces) {
    //   return alert("Input fields must include at least 2 characters")
    // } 
    return true
}