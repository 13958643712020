import React from "react";
import "./styles.scss";
import { useState } from "react";

export default function PreGFL({ preGFLRef }) {
  const [gflData, setGflData] = useState([
    {
      img: require("../../assets/images/preGFL-photo-1.png"),
    },
    {
      img: require("../../assets/images/preGFL-photo-2.png"),
    },
    {
      img: require("../../assets/images/preGFL-photo-3.png"),
    },
    {
      img: require("../../assets/images/preGFL-photo-4.png"),
    },
  ]);

  return (
    <div className="gfl-section-wrapper" ref={preGFLRef}>
      <h1>Pre-GFL</h1>

      <div className="data-gfl-wrapper">
        <div className="text-gfl-wrapper">
          <div className="paragraph-wrapper">
            <img
              src={require("../../assets/images/rectangle-blue.svg").default}
            />
            <p>
              The event will be held in Plovdiv, Bulgaria 15-17 september 2023.
            </p>
          </div>
          <div className="paragraph-wrapper">
            <img
              src={require("../../assets/images/rectangle-blue.svg").default}
            />
            <p>
              Plovdiv is an ancient city built around 7 hills, in southern
              Bulgaria. The Regional Archaeological Museum chronicles the city’s
              history, with exhibits including mosaic panels, clay lamps and
              early coins.
            </p>
          </div>

          <div className="paragraph-wrapper">
            <img
              src={require("../../assets/images/rectangle-blue.svg").default}
            />
          <p>
            The Roman-era Ancient Theatre of Philippopolis, which once seated
            around 6,000, now hosts opera and concerts. A 3D movie at the
            Emperor Hadrian – era Ancient Stadium re-creates Plovdiv in the 2nd
            century A.D.
          </p>
          </div>
        </div>

        <div className="gfl-wrapper">
          {gflData.map((item, i) => {
            return (
              <div className="gfl" key={`${i + 14}-${i}`}>
                <img src={item.img} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
