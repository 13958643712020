import React from 'react'
import { useEffect, useRef } from 'react'
import './styles.scss'
import { Header, HeroSection, About, Schedule, PreGFL, Package, Transportation, Hotels, Contact, PastEvents, Footer } from '../../components'

export default function LandingPage() {
  const aboutRef = useRef()
  const scheduleRef = useRef()
  const preGFLRef = useRef()
  const packageRef = useRef()
  const transportationRef = useRef()
  const hotelsRef = useRef()
  const contactRef = useRef()
  const eventsRef = useRef()

  const scroll = (ref) => {
    ref.current?.scrollIntoView({behavior: 'smooth', block: 'start', top: ref.current.offsetTop - 300})
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='landing-page-wrapper'>
      <Header scroll={scroll} aboutRef={aboutRef} scheduleRef={scheduleRef} preGFLRef={preGFLRef}
      packageRef={packageRef} transportationRef={transportationRef} hotelsRef={hotelsRef}
      contactRef={contactRef} eventsRef={eventsRef}
       />
      <HeroSection />
      <About aboutRef={aboutRef} />
      <Schedule scheduleRef={scheduleRef} />
      <PreGFL preGFLRef={preGFLRef} />
      <Package packageRef={packageRef} />
      <Transportation transportationRef={transportationRef} />
      <Hotels hotelsRef={hotelsRef} />
      <Contact contactRef={contactRef} />
      <PastEvents eventsRef={eventsRef} />
      <Footer />
    </div>
  )
}
