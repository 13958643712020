import React from "react";
import Lottie from "lottie-react";
import "./styles.scss";
import * as animationData from "../../../assets/animations/loading.json";

export default function Loading() {
  const style = {
    height: 200,
    width: 200,
  };

  return (
    <div className="modal-wrapper loading">
      <div className="content-wrapper">
        <Lottie animationData={animationData} loop={true} style={style} />
      </div>
    </div>
  );
}
